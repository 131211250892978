<template>
  <div class="indexbox">
    <nav-bar
      title="改善排行榜"
      left-arrow
      bg-color="#409eff"
      left-color="#ffffff"
      title-color="#fff"
    />
    <!-- <div class="nodates"
      v-if="list.length == 0"
    >
      <img style="width: 50%" src="@/assets/img/nodata.png" alt="" />
      <p>暂无数据</p>
    </div> -->
    <vant-up-down
      ref="list"
      class="listbox"
      @list="(val) => (list = val)"
      res="res.rows"
      url="/work/work/personnellist"
    >
      <div
        class="items"
        v-for="val in list"
        :key="val.num"
      >
        <div class="itemsleft">
          <div class="leftcon">
            <p>姓名：{{ val.proposer }}</p>
            <!-- <p
              class="rightcon"
              :style="{ color: filtercolor(val.auditStatus) }"
            >
              {{ val.auditStatus }}
            </p> -->
          </div>
          <p class="two">提案单位：{{ val.proposerUnit }}</p>
          <p class="two">得分：{{ val.score?val.score:0 }}</p>
          <p class="two">提报数量：{{ val.number?val.number:0 }}</p>
        </div>
        <!-- <div class="itembottom">
          <van-button
            class="btyn"
            v-if="
              filterpermissions('work:work:audit') &&
              val.auditStatus == '通过'
            "
            type="primary"
            color="#409eff"
            size="small"
            @click.stop="router('evaluation?id='+val.id)"
            >去评价</van-button
          >
        </div> -->
      </div>
    </vant-up-down>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      list: [],
      permissions: null,
    };
  },
  computed: {},
  watch: {},
  filters: {},
  methods: {},
  created() {
    this.permissions = JSON.parse(this.local.get("permissions"));
  },
  methods: {
    filterpermissions(value) {
      var str = "";
      for (var i in this.permissions) {
        if (value == this.permissions[i]||this.permissions[i]=="*:*:*") {
          str = this.permissions[i];
        }
      }
      return str ? true : false;
    },
    filtercolor(value) {
      var color = "";
      if (value == "通过") {
        color = "#07c160";
      }
      if (value == "待审核") {
        color = "#ffba00";
      }
      if (value == "待提交") {
        color = "#409eff";
      }
      if (value == "拒绝") {
        color = "red";
      }
      return color;
    },
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #ddd;
  .listbox {
    width: 100%;
    padding: 1.5vw 3vw;
    .items {
      background: #fff;
      margin-bottom: 3vw;
      border-radius: 2vw;
    }
    .itemsleft {
      width: 100%;
      padding: 3vw 3vw 1vw;
      font-size: 4vw;
      color: #666;
      p {
        line-height: 1.6;
      }
      .leftcon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;
        p:nth-child(1) {
          font-size: 4.4vw;
          font-weight: 550;
          width: 80%;
        }
      }
      .rightcon {
        width: 20%;
        text-align: right;
        font-size: 3.6vw;
        color: @theme;
        font-weight: 600;
      }
    }
    .itembottom {
      border-top: 1px solid #ddd;
      padding: 2px 3vw 6px;
      font-size: 4vw;
      text-align: right;
      .btyn {
        margin-left: 4vw;
        height: 7vw;
        border-radius: 1vw;
      }
    }
  }
}
</style>