<template>
  <div class="indexbox">
    <nav-bar
      title="详情"
      left-arrow
      bg-color="rgba(132, 147, 167,1)"
      left-color="#ffffff"
      title-color="#fff"
    />
   <p class="p1">您的申请已被拒绝<br>
    <span>抱歉!您预约的来访交流活动由于客观原因暂时无法安排接待,
请您与被访人沟通后另择时间。感谢您的理解!</span></p>
    <van-field
      class="divfield margin"
      v-model="obj.姓名"
      :rows="1"
      autosize
      type="textarea"
      readonly
      label="被访人姓名"
      placeholder="被访人姓名"
    />
    <van-field
    class="divfieldbt"
      v-model="obj.预约时间"
      readonly
      label="预约时间"
      placeholder="预约时间"
    />
    <van-field
      class="margin divfield"
      v-model="obj.访客姓名"
      readonly
      label="访客姓名"
      placeholder="访客姓名"
    />
    <van-field
      v-model="obj.来访事由"
      readonly
      label="来访事由"
      placeholder="来访事由"
    />
   
    <van-field
    
      v-model="obj.来访人数"
      readonly
      label="来访人数"
      placeholder="来访人数"
    />
    <van-field
    class="divfieldbt"
      v-model="obj.车牌号码"
       :rows="1"
      autosize
      readonly
      label="车牌号码"
      type="textarea"
      placeholder="车牌号码"
    />
    <div style="margin: 4vw 0">
      <van-button
        round
        type="primary"
        color="#9f4000"
        block
        @click="onSubmit"
        style="font-weight: 550"
        >重新预约</van-button
      >
    </div>
    
  </div>
</template>
    <script>
export default {
  data() {
    return {
      obj: {},
      id: "",
      type: "",
    };
  },

  computed: {},
  watch: {},
  methods: {},
  created() {
    this.id = this.$route.query.id;
    this.getdetail();
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  },
  methods: {
    getdetail() {
      this.$post({
        url: "/AuthVisitorManagement_list",
        loading: true,
        data:{查询条件: "b.ID=" + "'" + this.id + "'"},
        success: (res) => {
          this.obj = res.data?res.data.list[0]:{};
        },
        tip: () => {},
      });
    },
    onSubmit() {
      this.$router.push("register?id="+this.id)
    },
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  // background: #eee;
  background: linear-gradient(to bottom, rgba(132, 147, 167,1) 30%, #eee 15%);
  padding: 1vw 3vw 4vw;
  /deep/ .van-field__label {
    width: 19vw;
    margin-right: 2vw;
  }
  .p1{
    margin: 7vw 0 4vw;
    font-weight: 550;
    color:#fff;
    letter-spacing: 2px;font-size: 5vw;
    span{
      font-size: 4vw;
      letter-spacing: 1px;
      font-weight: 400;
    }
  }
  .margin {
    margin-top: 3vw;
  }
  .divfield {
    border-radius: 2vw 2vw 0 0;
  }
  .divfieldbt {
    border-radius: 0 0 2vw 2vw;
  }
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>