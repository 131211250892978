<template>
  <div class="indexbox">
    <nav-bar
      title="评价任务"
      left-arrow
      bg-color="#409eff"
      left-color="#ffffff"
      title-color="#fff"
    />
    <!-- <div class="nodates"
      v-if="list.length == 0"
    >
      <img style="width: 50%" src="@/assets/img/nodata.png" alt="" />
      <p>暂无数据</p>
    </div> -->
    <vant-up-down
      ref="list"
      class="listbox"
      @list="(val) => (list = val)"
      res="res.rows"
      url="/work/work/list"
      :data="{ state: '已完工',evaluateState:'未评价' }"
    >
      <div
        class="items"
        v-for="val in list"
        :key="val.num"
        @click="router('improvementdetail?id='+val.id+'&type=评价任务')"
      >
        <div class="itemsleft">
          <div class="leftcon">
            <p>项目名称：{{ val.projectName }}</p>
            <p
              class="rightcon"
              :style="{ color: filtercolor(val.improvementType) }"
            >
              {{ val.improvementType }}
            </p>
          </div>
          <p class="two">实施人：{{ val.principalPersonId }}</p>
          <p class="two">实际时间：{{ val.completionDeadline }}</p>
          <p class="two">提案人：{{ val.nickName }}</p>
          <p>
            
            提案编号：<span @click.stop="copyData(val.code)">{{ val.code }}</span>
          </p>
        </div>
        <div class="itembottom">
          <van-button
            class="btyn"
            v-if="
              filterpermissions('work:work:audit') &&
              val.auditStatus == '通过'
            "
            type="primary"
            color="#409eff"
            size="small"
            @click.stop="router('evaluation?id='+val.id)"
            >去评价</van-button
          >
          <!-- <van-button
            class="btyn"
            v-if="
              filterpermissions('work:work:audit') &&
              val.auditStatus == '待审核'
            "
            type="primary"
            size="small"
            color="#409eff"
            @click.stop="onSubmit"
            >通过</van-button
          > -->
        </div>
      </div>
    </vant-up-down>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      list: [],
      permissions: null,
    };
  },
  computed: {},
  watch: {},
  filters: {},
  methods: {},
  created() {
    this.permissions = JSON.parse(this.local.get("permissions"));
  },
  methods: {
    filterpermissions(value) {
      var str = "";
      for (var i in this.permissions) {
        if (value == this.permissions[i]||this.permissions[i]=="*:*:*") {
          str = this.permissions[i];
        }
      }
      return str ? true : false;
    },
    filtercolor(value) {
      var color = "";
      if (value == "成本类") {
        color = "#90ee90";
      }
      if (value == "安全类") {
        color = "#ff4500";
      }
      if (value == "质量类") {
        color = "#ff8c00";
      }
      if (value == "设备类") {
        color = "#077ff5";
      }
      if (value == "现场类") {
        color = "#348a7b";
      }
      if (value == "其他类") {
        color = "#c71585";
      }
      return color;
    },
    wangong(val,i) {

    },
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #ddd;
  .listbox {
    width: 100%;
    padding: 1.5vw 3vw;
    .items {
      background: #fff;
      margin-bottom: 3vw;
      border-radius: 2vw;
    }
    .itemsleft {
      width: 100%;
      padding: 3vw 3vw 1vw;
      font-size: 4vw;
      color: #666;
      p {
        line-height: 1.6;
      }
      .leftcon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;
        p:nth-child(1) {
          font-size: 4.4vw;
          font-weight: 550;
          width: 80%;
        }
      }
      .rightcon {
        width:20%;
        text-align: right;
        font-size: 3.6vw;
        color: #aaa;
        // font-weight: 600;
      }
    }
    .itembottom {
      border-top: 1px solid #ddd;
      padding: 2px 3vw 6px;
      font-size: 4vw;
      text-align: right;
      .btyn {
        margin-left: 4vw;
        height: 7vw;
        border-radius: 1vw;
      }
    }
  }
}
</style>