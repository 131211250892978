<template>
  <div class="indexbox">
    <nav-bar
      title="详情"
      left-arrow
      bg-color="#fc6700"
      left-color="#ffffff"
      title-color="#fff"
    />
    <p class="p1" v-if="compareTime(obj.预约时间)==0">已到达预约日期，请尽快完成签到!</p>
    <p class="p1" v-else>距离预约日期还有{{ compareTime(obj.预约时间) }}天，期待您的到访</p>
    <div class="div">
      <p class="p2">
       欢迎到访
      </p>
      <p class="p3">
        阳春啤酒
      </p>   
      <!-- <qr-code v-if="compareTime(obj.预约时间)==0" :text="obj.code" :size="200" /> -->
      <img v-if="compareTime(obj.预约时间)==0&&png" :src="png" style="width: 220px;" alt="">
      <!-- <img v-if="compareTime(obj.预约时间)==0&&png" :src="'data:image/jpg;base64,'+png" style="width: 200px;" alt=""> -->
      <img v-else src="@/assets/img/fangkeno.png" style="width: 220px;" alt="">
      <p class="p3" :style="{ color: compareTime(obj.预约时间)==0 ? '999' : '#000',margin:0 }">
        {{ compareTime(obj.预约时间)==0&&sn?sn:'******' }}
      </p>
      <p class="p2" :style="{ color: compareTime(obj.预约时间)==0 ? '666' : '#000' }">
        {{ compareTime(obj.预约时间)==0 ? "如果失效请" : "仅访问当天有效"
        }}<span @click="shuaxin" style="color: #1593fc" v-if="compareTime(obj.预约时间)==0"
          >&nbsp;刷新</span
        >
      </p>   
     
    </div>
    <van-field
      class="margin divfield"
      v-model="obj.姓名"
      readonly
      input-align="right"
      label="被访人姓名"
      placeholder="被访人姓名"
    />
    <van-field
      v-model="obj.访客姓名"
      readonly
      input-align="right"
      label="访客姓名"
      placeholder="访客姓名"
    />
    <van-field
      class="divfieldbt"
      v-model="obj.车牌号码"
      readonly
      input-align="right"
      label="车牌号码"
      placeholder="车牌号码"
    />
    
  </div>
</template>
    <script>
export default {
  data() {
    return {
      obj: {},
      sn:'',
      httpPath:'http://124.221.111.152:80',
      png:'',
      ishow: 1,
      id: "",
    };
  },

  computed: {},
  watch: {},
  methods: {},
  created() {
    this.id = this.$route.query.id;
    this.getdetail();
    this.shuaxin()
  },
  methods: {
    compareTime(time1) {
      const timestamp1 = new Date(time1).getTime();
      const timestamp2 = new Date().getTime();
      const oneDay = 24 * 60 * 60 * 1000; // 每天的毫秒数
      return Math.round(Math.abs((timestamp2 - timestamp1) / oneDay));
    },
    shuaxin() {
      this.$post({
        url: "/Authaccesscode",
        loading: true,
        data:{ID: this.id},
        success: (res) => {
          this.png = res.png
          this.sn = res.sn
        },
        tip: () => {},
      });
    },
    getdetail() {
      this.$post({
        url: "/AuthVisitorManagement_list",
        loading: true,
        data:{查询条件: "b.ID=" + "'" + this.id + "'"},
        success: (res) => {
          this.obj = res.data?res.data.list[0]:{};
        },
        tip: () => {},
      });
    },
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #ddd;
  padding: 1vw 3vw 4vw;
  /deep/ .van-field__label {
    width: 19vw;
    margin-right: 2vw;
  }
  .div {
    padding: 10vw 4.3vw 5vw;
    // display: flex;
    // justify-content: center;
    text-align: center;
    color: #323233;
    font-size: 3.7vw;
    line-height: 6.4vw;
    border-radius: 2vw;
    background-color: #fff;
  }
  .p1 {
    text-align: center;
    margin: 7vw 0 2vw;
    color:rgb(252, 103, 0);
    font-weight: 550;
    letter-spacing: 2px;
  }
  .p2 {
    margin: 0;
    font-size: 3.4vw;
    font-weight: 550;
    color:rgb(252, 103, 0)
  }
  .p3 {
    margin: 1vw 0 4vw;
    font-size: 6vw;
    font-weight: 550;
    color:rgb(252, 103, 0)
  }
  .margin {
    margin-top: 3vw;
  }
  .divfield {
    border-radius: 2vw 2vw 0 0;
  }
  .divfieldbt {
    border-radius: 0 0 2vw 2vw;
  }
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>