<template>
  <div class="indexbox">
    <nav-bar
      title="提报列表"
      left-arrow
      bg-color="#409eff"
      left-color="#ffffff"
      title-color="#fff"
      right-color="#fff"
      right-text="新增"
      @click-right="router('addImprovement')"
    />
    <div class="bag">
      <!-- color="#ff9292" -->
      <van-tabs type="card" v-model="listbox">
        <van-tab title="待提交">
          <vant-up-down
            ref="list"
            class="listbox"
            @list="(val) => (list = val)"
            res="res.rows"
            url="/work/work/list"
            :data="{ state: '已创建', auditStatus: '待提交' }"
          >
            <div
              class="items"
              v-for="val in list"
              :key="val.num"
              @click="
                router('improvementdetail?id=' + val.id + '&type=提报列表')
              "
            >
              <div class="itemsleft">
                <div class="leftcon">
                  <p>项目名称：{{ val.projectName }}</p>
                  <p
                    class="rightcon"
                    :style="{ color: filtercolor(val.state) }"
                  >
                    {{ val.state }}
                  </p>
                </div>
                <p class="two">提案人：{{ val.nickName }}</p>
                <p class="two">提案单位：{{ val.deptName }}</p>
                <p class="two">提案时间：{{ val.createTime }}</p>
                <p>
                  提案编号：<span @click.stop="copyData(val.code)">{{
                    val.code
                  }}</span>
                </p>
              </div>
              <div class="itembottom" v-if="val.auditStatus != '通过'">
                <van-button
                  class="btyn"
                  type="primary"
                  color="red"
                  size="small"
                  @click.stop="delt(val.id)"
                  v-if="
                    filterpermissions('work:work:remove') &&
                    val.state == '已创建' &&
                    val.auditStatus == '待提交'
                  "
                  >删除</van-button
                >
                <van-button
                  class="btyn"
                  type="primary"
                  size="small"
                  v-if="
                    filterpermissions('work:work:edit') &&
                    val.state == '已创建' &&
                    val.auditStatus == '待提交'
                  "
                  color="#07c160"
                  @click.stop="router('editImprovement?id=' + val.id)"
                  >修改</van-button
                >
                <van-button
                  class="btyn"
                  v-if="
                    filterpermissions('work:work:cancel') &&
                    val.auditStatus == '待审核'
                  "
                  type="primary"
                  color="#ff9292"
                  size="small"
                  @click.stop="chexiao(val, val.id)"
                  >撤销</van-button
                >
                <van-button
                  class="btyn"
                  type="primary"
                  v-if="
                    filterpermissions('work:work:submit') &&
                    val.state == '已创建' &&
                    val.auditStatus == '待提交'
                  "
                  size="small"
                  color="#409eff"
                  @click.stop="onSubmit(val, val.id)"
                  >提交</van-button
                >
              </div>
            </div>
          </vant-up-down>
        </van-tab>
        <van-tab title="全部">
          <vant-up-down
            ref="list"
            class="listbox"
            @list="(val) => (lists = val)"
            res="res.rows"
            url="/work/work/list"
          >
            <div
              class="items"
              v-for="val in lists"
              :key="val.num"
              @click="
                router('improvementdetail?id=' + val.id + '&type=提报列表')
              "
            >
              <div class="itemsleft">
                <div class="leftcon">
                  <p>项目名称：{{ val.projectName }}</p>
                  <p
                    class="rightcon"
                    :style="{ color: filtercolor(val.state) }"
                  >
                    {{ val.state }}
                  </p>
                </div>
                <p class="two">提案人：{{ val.nickName }}</p>
                <p class="two">提案单位：{{ val.deptName }}</p>
                <p class="two">提案时间：{{ val.createTime }}</p>
                <p>
                  提案编号：<span @click.stop="copyData(val.code)">{{
                    val.code
                  }}</span>
                </p>
              </div>
              <div class="itembottom" v-if="val.auditStatus != '通过'">
                <van-button
                  class="btyn"
                  type="primary"
                  color="red"
                  size="small"
                  @click.stop="delt(val.id)"
                  v-if="
                    filterpermissions('work:work:remove') &&
                    val.state == '已创建' &&
                    val.auditStatus == '待提交'
                  "
                  >删除</van-button
                >
                <van-button
                  class="btyn"
                  type="primary"
                  size="small"
                  v-if="
                    filterpermissions('work:work:edit') &&
                    val.state == '已创建' &&
                    val.auditStatus == '待提交'
                  "
                  color="#07c160"
                  @click.stop="router('editImprovement?id=' + val.id)"
                  >修改</van-button
                >
                <van-button
                  class="btyn"
                  v-if="
                    filterpermissions('work:work:cancel') &&
                    val.auditStatus == '待审核'
                  "
                  type="primary"
                  color="#ff9292"
                  size="small"
                  @click.stop="chexiao(val, val.id)"
                  >撤销</van-button
                >
                <van-button
                  class="btyn"
                  type="primary"
                  v-if="
                    filterpermissions('work:work:submit') &&
                    val.state == '已创建' &&
                    val.auditStatus == '待提交'
                  "
                  size="small"
                  color="#409eff"
                  @click.stop="onSubmit(val, val.id)"
                  >提交</van-button
                >
              </div>
            </div>
          </vant-up-down>
        </van-tab>
      </van-tabs>
    </div>
    <!-- <div class="nodates"
      v-if="list.length == 0"
    >
      <img style="width: 50%" src="@/assets/img/nodata.png" alt="" />
      <p>暂无数据</p>
    </div> -->
  </div>
</template>
    <script>
export default {
  data() {
    return {
      list: [],
      lists: [],
      listbox:'',
      permissions: null,
    };
  },
  computed: {},
  watch: {},
  // beforeRouteEnter(to, from, next){
  //   console.log(from )
  //     if (from && from.name === 'improvementdetail') {
  //       // 如果从详情页面返回，不进行页面刷新
  //       next();
  //     } else {
  //       // 正常进入列表页面
  //       next();
  //     }
  //   },
  filters: {},
  methods: {},
  created() {
    this.permissions = JSON.parse(this.local.get("permissions"));
  },
  methods: {
    filterpermissions(value) {
      var str = "";
      for (var i in this.permissions) {
        if (value == this.permissions[i] || this.permissions[i] == "*:*:*") {
          str = this.permissions[i];
        }
      }
      return str ? true : false;
    },
    filtercolor(value) {
      var color = "";
      if (value == "进行中") {
        color = "#07c160";
      }
      if (value == "已创建") {
        color = "#ffba00";
      }
      if (value == "暂不执行") {
        color = "#ff9292";
      }
      if (value == "已完工") {
        color = "#ffba00";
      }
      if (value == "拒绝") {
        color = "red";
      }
      return color;
    },
    chexiao(val, id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要撤销吗?",
          confirmButtonColor: "#409eff",
        })
        .then(() => {
          this.$get({
            url: "/work/work/cancelWork?ids=" + id,
            loading: true,
            success: (res) => {
              val.auditStatus = "待提交";
              this.toast({
                message: "撤销成功",
                onClose: () => {},
              });
            },
            tip: () => {},
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    onSubmit(val, id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要提交吗?",
          confirmButtonColor: "#409eff",
        })
        .then(() => {
          this.$get({
            url: "/work/work/subWork?ids=" + id,
            loading: true,
            success: (res) => {
              val.auditStatus = "待审核";
              this.toast({
                message: "提交成功",
                onClose: () => {},
              });
            },
            tip: () => {},
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    delt(id) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要删除吗?",
          confirmButtonColor: "#409eff",
        })
        .then(() => {
          this.$delete({
            url: "/work/work/" + id,
            loading: true,
            success: (res) => {
              this.list.splice(i, 1);
              this.toast({
                message: "删除成功",
                onClose: () => {},
              });
            },
            tip: () => {},
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #ddd;
  /deep/.van-tabs__wrap {
    height: 8vw;
    background: #eee;
    padding: 1vw 0 10vw;
    position: fixed;
    top: 12vw;
    left: 0;
    right: 0;
    z-index: 999;
  }
  .listbox {
    width: 100%;
    padding: 12vw 3vw 2vw;
    .items {
      background: #fff;
      margin-bottom: 3vw;
      border-radius: 2vw;
    }
    .itemsleft {
      width: 100%;
      padding: 3vw 3vw 1vw;
      font-size: 4vw;
      color: #666;
      p {
        line-height: 1.6;
      }
      .leftcon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;
        p:nth-child(1) {
          font-size: 4.4vw;
          font-weight: 550;
          width: 80%;
        }
      }
      .rightcon {
        width: 20%;
        text-align: right;
        font-size: 3.6vw;
        color: #aaa;
        // font-weight: 600;
      }
    }
    .itembottom {
      border-top: 1px solid #ddd;
      padding: 2px 3vw 6px;
      font-size: 4vw;
      text-align: right;
      .btyn {
        margin-left: 4vw;
        height: 7vw;
        border-radius: 1vw;
      }
    }
  }
}
</style>