<template>
  <div class="indexbox">
    <nav-bar
      title="审核信息"
      left-arrow
      bg-color="#409eff"
      left-color="#ffffff"
      title-color="#fff"
    />
    <van-field
      class="divfield margin"
      v-model="projectName"
      :rows="1"
      autosize
      type="textarea"
      disabled
      label="项目名称"
      placeholder="项目名称"
    />
    <van-field
      v-model="code"
      disabled
      label="提案编号"
      placeholder="提案编号"
    />
    <van-field name="uploader" disabled label="改善前照片">
      <template #input>
        <van-uploader
          v-model="fileList"
          disabled
          :deletable="false"
          :max-count="1"
        >
        </van-uploader>
      </template>
    </van-field>
    <van-field
      v-model="statusDescription"
      :rows="1"
      disabled
      autosize
      label="现状描述"
      type="textarea"
      placeholder="现状描述"
    />
    <van-field
      v-model="consequenceAnalysis"
      :rows="1"
      autosize
      disabled
      label="后果分析"
      type="textarea"
      placeholder="后果分析"
    />
    <van-field
      v-model="jobContent"
      :rows="1"
      autosize
      disabled
      label="改善方案"
      type="textarea"
      placeholder="改善方案"
    />
    <van-field
      v-model="deptName"
      disabled
      label="提案单位"
      placeholder="提案单位"
    />
    <van-field
      v-model="createTime"
      disabled
      label="提案时间"
      placeholder="提案时间"
    />
    <van-field
      class="divfieldbt"
      v-model="nickName"
      disabled
      label="提案人"
      :border="false"
      placeholder="提案人"
    />
    <van-field
      class="margin divfield"
      v-model="datetime"
      readonly
      label="计划时间"
      placeholder="点击选择计划完成时间"
      @click="showPicker = true"
    />
    <van-field
      v-model="improvementType"
      readonly
      label="改善类型"
      placeholder="点击选择改善类型"
      @click="showtype = true"
    />
    <van-field
      v-model="workLeaderName"
      :rows="1"
      readonly
      label="实施人"
      type="textarea"
      autosize
      placeholder="实施人"
      @click="showgroup = true"
    />
    <van-field
      class="divfieldbt"
      v-model="remark"
      :rows="1"
      autosize
      label="备注"
      type="textarea"
      placeholder="备注"
    />
    <van-popup v-model="showtype" position="bottom">
      <van-picker
        show-toolbar
        :columns="typelist"
        @confirm="onConfirmtype"
        @cancel="showtype = false"
      />
    </van-popup>
    <van-popup v-model="showgroup" position="bottom" round>
      <div class="disflex">
        <van-button
          class="btn"
          size="small"
          style="color: #666"
          block
          @click="showgroup = false"
          >取消</van-button
        >
        <van-button
          class="btn"
          size="small"
          style="color: #409eff"
          block
          @click="groupsure"
          >确认</van-button
        >
      </div>
      <van-checkbox-group v-model="checkbox" style="margin-bottom: 4vw">
        <van-cell-group>
          <van-cell
            v-for="(item, index) in grouplist"
            clickable
            :key="index"
            :title="item.nickName"
            @click.native="$refs.checkboxes[index].toggle()"
          >
            <template #right-icon>
              <van-checkbox :name="item.userId" ref="checkboxes" />
            </template>
          </van-cell>
        </van-cell-group> </van-checkbox-group
    ></van-popup>
    <van-calendar v-model="showPicker" @confirm="onConfirm" />
    <div class="postionflex">
      <van-button
        class="btyn"
        v-if="
          filterpermissions('work:work:cancel') &&
          obj &&
          obj.auditStatus == '待审核'
        "
        type="primary"
        color="#ff9292"
        @click.stop="bohui"
        >驳回</van-button
      >
      <van-button
        class="btyn"
        type="primary"
        v-if="
          filterpermissions('work:work:cancel') &&
          obj &&
          obj.auditStatus == '待审核'
        "
        color="#ffba00"
        @click.stop="zanbuzhixing"
        >暂不执行</van-button
      >
      <van-button
        class="btyn"
        type="primary"
        color="#409eff"
        block
        @click="onSubmit"
        style="font-weight: 550"
        >审核通过</van-button
      >
    </div>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      projectName: "",
      statusDescription: "",
      jobContent: "",
      consequenceAnalysis: "",
      fileList: [],
      head_img: "",
      datetime: "",
      code: "",
      showPicker: false,
      id: "",
      obj: null,
      showgroup: false,
      showtype: false,
      grouplist: [],
      checkbox: [],
      deptName: "",
      createTime: "",
      nickName: "",
      typelist: ["安全类", "质量类", "设备类", "成本类", "现场类", "其它"],
      improvementType: "",
      remark: "",
      workLeaderName: "",
      permissions: null,
    };
  },

  computed: {},
  watch: {},
  methods: {},
  created() {
    this.permissions = JSON.parse(this.local.get("permissions"));
    this.id = this.$route.query.id;
    this.getdetail();
    this.listInfo();
  },
  methods: {
    listInfo() {
      this.grouplist = [];
      this.$get({
        url: "/system/user/listInfo",
        loading: true,
        success: (res) => {
          this.grouplist = res.data ? res.data : null;
        },
        tip: () => {},
      });
    },
    filterpermissions(value) {
      var str = "";
      for (var i in this.permissions) {
        if (value == this.permissions[i] || this.permissions[i] == "*:*:*") {
          str = this.permissions[i];
        }
      }
      return str ? true : false;
    },
    getdetail() {
      this.$get({
        url: "/work/work/" + this.id,
        loading: true,
        success: (res) => {
          this.obj = res.data ? res.data : null;
          this.code = res.data.code;
          this.projectName = res.data.projectName;
          this.deptName = res.data.deptName;
          this.createTime = res.data.createTime;
          this.nickName = res.data.nickName;
          this.fileList.push({ url: res.data.beforePhoto });
          this.consequenceAnalysis = res.data.consequenceAnalysis;
          this.statusDescription = res.data.statusDescription;
          this.jobContent = res.data.jobContent;
        },
        tip: () => {},
      });
    },
    bohui() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要驳回吗?",
          confirmButtonColor: "#409eff",
        })
        .then(() => {
          var obj = this.obj;
          obj.state = "拒绝";
          obj.auditStatus = "拒绝";
          this.$put({
            url: "/work/work",
            loading: true,
            data: obj,
            success: (res) => {
              this.toast({
                message: "驳回成功",
                onClose: () => {
                  this.$router.go(-1);
                },
              });
            },
            tip: () => {},
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    zanbuzhixing() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要暂不执行吗?",
          confirmButtonColor: "#409eff",
        })
        .then(() => {
          var obj = this.obj;
          obj.state = "暂不执行";
          obj.auditStatus = "通过";
          this.$put({
            url: "/work/work",
            loading: true,
            data: obj,
            success: (res) => {
              this.toast({
                message: "处理成功",
                onClose: () => {
                  this.$router.go(-1);
                },
              });
            },
            tip: () => {},
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    groupsure() {
      this.showgroup = false;
      var workLeaderName = [];
      this.grouplist.forEach((e) => {
        for (var i = 0; i < this.checkbox.length; i++) {
          if (e.userId == this.checkbox[i]) {
            workLeaderName.push(e.nickName);
            break;
          }
        }
      });
      this.workLeaderName = workLeaderName.join(",");
    },
    onConfirmtype(e) {
      this.improvementType = e;
      this.showtype = false;
    },
    onConfirm(val) {
      this.datetime = `${val.getFullYear()}-${
        val.getMonth() + 1
      }-${val.getDate()}`;
      this.showPicker = false;
    },
    onSubmit() {
      if (!this.datetime) return this.$toast("请选择计划完成时间");
      if (!this.improvementType) return this.$toast("请选择改善类型");
      if (this.checkbox.length < 1) return this.$toast("请选择实施人");
      this.obj.planDeadline = this.datetime;
      this.obj.improvementType = this.improvementType;
      this.obj.remark = this.remark;
      this.obj.workLeaderId = this.checkbox.join(",");
      this.obj.state = "进行中";
      this.obj.auditStatus = "通过";
      this.$put({
        url: "/work/work",
        loading: true,
        data: this.obj,
        success: (res) => {
          this.toast({
            message: "审核成功",
            onClose: () => {
              this.$router.go(-1);
            },
          });
        },
        tip: () => {},
      });
    },
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #ddd;
  padding: 1vw 3vw 20vw;
  /deep/ .van-field__label {
    width: 19vw;
    margin-right: 2vw;
  }
  /deep/.van-picker__cancel {
    font-size: 4vw;
  }
  /deep/.van-picker__confirm {
    color: #409eff;
    font-size: 4vw;
  }
  .margin {
    margin-top: 3vw;
  }
  .divfield {
    border-radius: 2vw 2vw 0 0;
  }
  .divfieldbt {
    border-radius: 0 0 2vw 2vw;
  }

  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
  .postionflex {
    display: flex;
    background: #eee;
    justify-content: space-between;
    position: fixed;
    padding: 3vw;
    bottom: 0vw;
    left: 0vw;
    right: 0vw;
    .btyn {
      height: 9vw;
      width: 30%;
      border-radius: 1vw;
    }
  }
  .disflex {
    padding: 4vw 0vw 2vw;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 20%;
      border: 0;
      font-size: 4vw;
    }
  }
}
</style>