<template>
  <div class="loginbox">
    <nav-bar title="访客预约" left-arrow
      bg-color="#fc6700"
      @click-left="$router.go(-1)"
      left-color="#ffffff"
      title-color="#fff"/>
    <div class="frombox"> 
      <!-- <van-field clearable v-model="name1" type="text" maxlength="20" label="被访人姓名" placeholder="请输入被访人姓名" :border="false" /> -->
      <!-- <van-field clearable v-model="tell1" type="tel1" maxlength="11" label="被访人手机号" placeholder="请输入被访人手机号" :border="false" /> -->
      <van-field clearable v-model="name" type="text" maxlength="20" label="访客姓名" placeholder="请输入访客姓名" :border="false" />
      <van-field clearable v-model="tell" type="tel" maxlength="11" label="访客手机号" placeholder="请输入访客手机号" :border="false" />
      <van-field clearable v-model="card" type="number" maxlength="24" label="访客身份证号" placeholder="请输入访客身份证号" :border="false" />
      <van-field clearable @click="showtype = true" v-model="reason" label="来访事由"
      readonly  placeholder="请选择来访事由" :border="false" />
      <van-field
          v-model="times"
          label="预约时间"
          placeholder="请选择预约时间"
          :border="false"
          readonly
          @click="changedata"
        />
      <van-field clearable maxlength="6" v-model="chepai" type="text" label="车牌号码" placeholder="请输入车牌号码"
        :border="false" />
      <van-field clearable maxlength="6" v-model="personum" type="text" label="来访人数" placeholder="请输入来访人数"
        :border="false" />
      <!-- <van-field clearable v-model="timenum" type="number" label="访客时长" placeholder="请输入访客时长"
        :border="false" /> -->
      <div class="btnbox">
        <van-button round type="primary" color="#9f4000" block @click="registBtn">提交申请</van-button>
      </div>
    </div>
    <van-popup v-model="dateshow" position="bottom" :style="{ width: '100%' }">
        <van-datetime-picker
          @confirm="onConfirm"
          @cancel="dateshow = false"
          v-model="currentDate"
          type="datehour"
          title="选择年月日小时"
        />
      </van-popup>
      <van-popup v-model="showtype" position="bottom">
      <van-picker
        show-toolbar
        :columns="typelist"
        @confirm="onConfirmtype"
        @cancel="showtype = false"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      logo: "",
      name:'',
      name1:'',
      showtype: false,
      tell1: "",
      typelist: ["来访事由1", "面试", "业务沟通", "预约验厂"],
      currentDate:new Date(),
      tell: "",
      openids: '',
      times:'',
      card: "",
      reason: "",
      chepai: "",
      personum: "",
      timenum: "",
      downpath: "",
      dateshow:false
    }
  },
  computed: {},
  watch: {},
  methods: {
    changedata() {
      this.dateshow = true;
    },
    getdetail() {
      this.$post({
        url: "/AuthVisitorManagement_list",
        loading: true,
        data:{ID: this.id},
        success: (res) => {
          this.name = res.data.name;
          this.tell = res.data.tell;
          this.card = res.data.card;
          this.reason = res.data.reason;
          this.personum = res.data.personum;
        },
        tip: () => {},
      });
    },
    onConfirmtype(e) {
      this.reason = e;
      this.showtype = false;
    },
    onConfirm(date) {
      this.dateshow = false;
      this.times = this.formatDate(date);
      
    },
    getopenids() {
      // 尝试从LocalStorage获取openids
      const storedopenids = localStorage.getItem('openids');
      if (storedopenids) {
        this.openids = storedopenids;
      } else {
        // 如果没有，生成一个新的openids并存储
        this.openids = this.generateUUID();
        localStorage.setItem('openids', this.openids);
      }
    },
    generateUUID() {
      // 使用现代浏览器提供的API生成UUID
      let result, i, j;
      result = '';
      for (i = 0; i < 32; i++) {
        j = (Math.random() * 16) | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
          result += '-';
        }
        result += (i === 12 ? 4 : (i === 16 ? (j & 3) | 8 : j)).toString(16);
      }
      return result;
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:00:00`;
    },
    registBtn() {
      // if (!this.name1) return this.$toast("请输入被访人姓名")
      // if (!this.tell1) return this.$toast("请输入被访人手机号")
      // if (!/^1[23456789]\d{9}$/.test(this.tell1)) return this.$toast("请输入正确格式的被访人手机号")
      if (!this.name) return this.$toast("请输入访客姓名")
      if (!this.tell) return this.$toast("请输入访客手机号")
      if (!/^1[23456789]\d{9}$/.test(this.tell)) return this.$toast("请输入正确格式的访客手机号")
      if (!this.card) return this.$toast("请输入访客身份证号")
      if (!this.reason) return this.$toast("请输入来访事由")
     if (!this.times) return this.$toast("请选择预约时间")
    //  if (!this.chepai) return this.$toast("请输入车牌号码")
      // if (!/^\d{6}$/.test(this.chepai)) return this.$toast("车牌号码为6位格式")
      if (!this.personum) return this.$toast("请输入来访人数")
      // if (!this.timenum) return this.$toast("请输入访客时长")

      this.$post({
        url: "/AuthVisitorManagement_add",
        loading: true,
        noToken: true,
        data: {
          '员工ID':this.local.get("phone"),
          // '被访人手机号': this.tell1,
          '访客姓名':this.name,
          '设备ID':this.openids,
          '访客手机': this.tell,
          '访客身份证号码': this.card,
          '来访事由': this.reason,
          '车牌号码': this.chepai,
          '预约时间': this.times,
          '来访人数': this.personum,
          // '访客时长': this.timenum,
        }, 
        success: () => {
          this.toast({
            message: "提交成功",
            onClose: () => {
              this.goReplace("/");
            },
          })
        },
        tip: () => { },
      })
    },
  },
  created() {
    // this.getopenids();
    const storedopenids = localStorage.getItem('openids');
    this.openids = storedopenids;
    if(this.$route.query.id){
      this.id = this.$route.query.id;
      this.getdetail();
    }
  },
}
</script>
<style lang="less" scoped>
.loginbox {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 10px 15px;
  .frombox {
    width: 100%;
    /deep/.van-cell {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 10px;
      padding: 10px;
      background: rgba(250, 250, 250, 0.8);
      .van-field__label {
        text-align: center;
      }
    }
    .btnbox {
      width: 100%;
      margin: 20px 0;
    }
  }
}
</style>
