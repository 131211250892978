<template>
  <div class="indexbox">
    <nav-bar
      title="审批任务"
      left-arrow
      bg-color="#409eff"
      left-color="#ffffff"
      title-color="#fff"
    />
    <vant-up-down
      ref="list"
      class="listbox"
      @list="(val) => (list = val)"
      res="res.rows"
      url="/work/work/approvalManagementList"
      :data="{ auditStatus: '待审核' }"
    >
      <div
        class="items"
        v-for="(val, i) in list"
        :key="i"
        @click="router('improvementdetail?id='+val.id+'&type=审批任务')"
      >
        <div class="itemsleft">
          <div class="leftcon">
            <p>项目名称：{{ val.projectName }}</p>
            <!-- <p
              class="rightcon"
              :style="{ color: filtercolor(val.auditStatus) }"
            >
              {{ val.auditStatus }}
            </p> -->
          </div>
          <p class="two">提案人：{{ val.nickName }}</p>
          <p class="two">提案单位：{{ val.deptName }}</p>
          <p class="two">提案时间：{{ val.createTime }}</p>
          <p>
            提案编号：<span @click.stop="copyData(val.code)">{{ val.code }}</span>
          </p>
        </div>
        <div class="itembottom">
          <!-- <van-button
            class="btyn"
            v-if="
              filterpermissions('work:work:cancel') &&
              val.auditStatus == '待审核'
            "
            type="primary"
            color="#ff9292"
            size="small"
            @click.stop="bohui(val, i)"
            >驳回</van-button
          > -->
          <van-button
            class="btyn"
            v-if="
              filterpermissions('work:work:cancel') &&
              val.auditStatus == '待审核'
            "
            type="primary"
            size="small"
            color="#409eff"
            @click.stop="router('improvementshenhe?id='+val.id)"
            >审核</van-button
          >
          <!-- <van-button
            class="btyn"
            type="primary"
            v-if="
              filterpermissions('work:work:cancel') &&
              val.auditStatus == '待审核'
            "
            color="#ffba00"
            size="small"
            @click.stop="zanbuzhixing(val, i)"
            >暂不执行</van-button
          > -->
        </div>
      </div>
    </vant-up-down>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      list: [],
      permissions: null,
    };
  },
  computed: {},
  watch: {},
  filters: {},
  methods: {},
  created() {
    this.permissions = JSON.parse(this.local.get("permissions"));
  },
  methods: {
    filterpermissions(value) {
      var str = "";
      for (var i in this.permissions) {
        if (value == this.permissions[i] || this.permissions[i] == "*:*:*") {
          str = this.permissions[i];
        }
      }
      return str ? true : false;
    },
    filtercolor(value) {
      var color = "";
      if (value == "通过") {
        color = "#07c160";
      }
      if (value == "待审核") {
        color = "#ffba00";
      }
      if (value == "待提交") {
        color = "#409eff";
      }
      if (value == "拒绝") {
        color = "red";
      }
      return color;
    },
    bohui(val, i) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要驳回吗?",
          confirmButtonColor: "#409eff",
        })
        .then(() => {
          val.auditStatus = "拒绝";
          val.state = "拒绝";
          this.$put({
            url: "/work/work",
            loading: true,
            data: val,
            success: (res) => {
              this.list.splice(i, 1);
              this.toast({
                message: "驳回成功",
                onClose: () => {},
              });
            },
            tip: () => {},
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    zanbuzhixing(val, i) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认要暂不执行吗?",
          confirmButtonColor: "#409eff",
        })
        .then(() => {
          val.state = "暂不执行";
          val.auditStatus = "通过";
          this.$put({
            url: "/work/work",
            loading: true,
            data: val,
            success: (res) => {
              this.list.splice(i, 1);
              this.toast({
                message: "处理成功",
                onClose: () => {},
              });
            },
            tip: () => {},
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #ddd;
  .listbox {
    width: 100%;
    padding: 1.5vw 3vw;
    .items {
      background: #fff;
      margin-bottom: 3vw;
      border-radius: 2vw;
    }
    .itemsleft {
      width: 100%;
      padding: 3vw 3vw 1vw;
      font-size: 4vw;
      color: #666;
      p {
        line-height: 1.6;
      }
      .leftcon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;
        p:nth-child(1) {
          font-size: 4.4vw;
          font-weight: 550;
          width: 80%;
        }
      }
      .rightcon {
        width: 20%;
        text-align: right;
        font-size: 3.6vw;
        color: @theme;
      }
    }
    .itembottom {
      border-top: 1px solid #ddd;
      padding: 2px 3vw 6px;
      font-size: 4vw;
      text-align: right;
      .btyn {
        margin-left: 4vw;
        height: 7vw;
        border-radius: 1vw;
      }
    }
  }
}
</style>