<template>
  <div class="indexbox">
    <!-- right-text="收藏" -->
    <nav-bar
      title="精益管理"
      bg-color="#409eff"
      title-color="#fff"
      right-color="#fff"
    />
    <div class="boxs">
      <van-grid :column-num="2">
        <van-grid-item
          v-for="(value, i) in list"
          :key="i"
          :to="value.rout"
          :icon="value.img"
          :text="value.text"
        />
      </van-grid>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      list: [
        { text: "改善提报", img: require("@/assets/img/gen.png"),rout:'/improvementList' },
        { text: "审批任务", img: require("@/assets/img/gen.png"),rout:'/improveApproval' },
        { text: "改善任务", img: require("@/assets/img/gen.png"),rout:'/improvementTask' },
        { text: "改善知识库", img: require("@/assets/img/gen.png"),rout:'/improveKnowledgeBase' },
        { text: "评价任务", img: require("@/assets/img/gen.png"),rout:'/evaluationTask' },
        { text: "改善排行榜", img: require("@/assets/img/gen.png"),rout:'/improveLeaderboard' },
      ],
    };
  },
  mounted() { this.getInfo()},
  methods: {
    getInfo(){
      this.$get({
        url: "/getInfo",
        loading: true,
        success: (res) => {
          this.local.set("permissions", JSON.stringify(res.permissions))
        },
        tip: () => { },
      })
    },
  },
};
</script>
<style scoped lang="less">
.indexbox {
  width: 100%;
  /deep/.van-grid-item__icon{
    font-size: 20vw;
  }
  /deep/.van-grid-item__text{
    font-size: 5vw;
    // font-weight: 550;
  }
}
</style>
