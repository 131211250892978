<template>
  <div class="indexbox">
    <nav-bar title="访客记录" bg-color="#fc6700" title-color="#fff" />
    <!-- <van-search
      show-action
      v-model="value"
      shape="round"
      background="#fc6700"
      placeholder="输入搜索访客手机号查询"
    >
      <template #action>
        <div @click="getdetail" style="color: #fff; margin-right: 2vw">
          搜索
        </div>
      </template></van-search
    > -->
    <div class="nodates" v-if="list.length == 0">
      <img style="width: 14%" src="@/assets/img/nodata.png" alt="" />
      <p>暂无数据</p>
    </div>
    <div class="listbox">
      <!-- :data="{ state: '进行中' }" -->
      <div
        class="items"
        v-for="val in list"
        :key="val.num"
        @click="goo(val)"
      >
        <div class="itemsleft">
          <div class="leftcon">
            <p>被访人：{{ val.姓名 }}</p>
            <p class="rightcon" :style="{ color: filtercolor(val.状态) }">
              {{ val.状态 }}
            </p>
          </div>
          <p>访客姓名：{{ val.访客姓名 }}</p>
          <p>访客手机：{{ val.访客手机 }}</p>
          <p>车牌号码：{{ val.车牌号码 }}</p>
          <p>预约时间：{{ val.预约时间 }}</p>
          <p>来访人数：{{ val.来访人数 }}</p>
          <p v-if="val.状态=='已签到'">来访时间：{{ val.来访时间 }}</p>
          <p v-if="val.状态=='已签到'">离开时间：{{ val.离开时间 }}</p>
        </div>
        <div class="itembottom" v-if="val.状态=='已拒绝'">
          <van-button
            class="btyn"
            type="primary"
            color="#dbffdd"
            size="small"
            @click.stop="router('register?id='+val.ID)"
            >重新预约</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      list: [],
      value: "",
      openids: '',
      permissions: null,
    };
  },
  computed: {},
  watch: {},
  filters: {},
  methods: {},
  created() {
    const storedopenids = localStorage.getItem('openids');
      if (storedopenids) {
        this.openids = storedopenids;
      }
    this.getdetail();
  },
  methods: {
    goo(val){
      if(val.状态=='待到访'){
        this.router('fangkedetail1?id=' + val.ID)
      }else if(val.状态=='已拒绝'){
        this.router('fangkedetail?id=' + val.ID)
      }else{
        this.router('fangkedetailn?id=' + val.ID)
      }
    }, 
    getdetail() {
      if (!this.openids) {
        return false;
      }
      var dat = {
        当前页数: 1,
        每页数量: 10000,
        查询条件: "b.设备ID=" + "'" + this.openids + "'",
      };
      this.$post({
        url: "/AuthVisitorManagement_list",
        loading: true,
        data: dat,
        success: (res) => {
          this.list = res.data ? res.data.list : [];
        },
        tip: () => {},
      });
    },
    filtercolor(value) {
      var color = "";
      if (value == "已签到") {
        color = "#cde6fe";
      }
      if (value == "待到访") {
        color = "#dbffdd";
      }
      if (value == "已拒绝") {
        color = "#eceeee";
      }
      return color;
    },
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #eee;
  /deep/ .van-search__action:active {
    background-color: rgba(242, 243, 245, 0.1);
  }
  .listbox {
    width: 100%;
    padding: 1.5vw 3vw 13vw;
    .items {
      background: #fff;
      margin-bottom: 3vw;
      border-radius: 2vw;
    }
    .itemsleft {
      width: 100%;
      padding: 3vw 3vw 1vw;
      font-size: 4vw;
      color: #666;
      p {
        line-height: 1.6;
      }
      .leftcon {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;
        p:nth-child(1) {
          font-size: 4.4vw;
          font-weight: 550;
          width: 80%;
        }
      }
      .rightcon {
        width: 20%;
        text-align: right;
        font-size: 3.6vw;
        color: #aaa;
        // font-weight: 600;
      }
    }
    .itembottom {
      border-top: 1px solid #ddd;
      padding: 2px 3vw 6px;
      font-size: 4vw;
      text-align: right;
      .btyn {
        margin-left: 4vw;
        height: 7vw;
        border-radius: 1vw;
      }
    }
  }
}
</style>
