<template>
  <div class="indexbox">
    <nav-bar
      title="改善提报"
      left-arrow
      bg-color="#409eff"
      left-color="#ffffff"
      title-color="#fff"
    />
      <van-field
        class="divfield"
        v-model="projectName"
        :rows="1"
      autosize
      type="textarea"
        label="项目名称"
        placeholder="项目名称"
      />
      <van-field name="uploader" label="改善前照片" class="divfield">
        <template #input>
          <van-uploader v-model="fileList" :max-count="1" :after-read="afterRead">
          </van-uploader>
        </template>
      </van-field>
      <van-field
        class="divfield"
        v-model="statusDescription"
        :rows="2"
        autosize
        label="现状描述"
        type="textarea"
        maxlength="200"
        placeholder="现状描述"
        show-word-limit
      />
      <van-field
        class="divfield"
        v-model="consequenceAnalysis"
        :rows="2"
        autosize
        label="后果分析"
        type="textarea"
        maxlength="150"
        placeholder="后果分析"
        show-word-limit
      />
      <van-field
        class="divfield"
        v-model="jobContent"
        :rows="2"
        autosize
        label="改善方案"
        type="textarea"
        maxlength="300"
        placeholder="改善方案"
        show-word-limit
      />
      <div style="margin: 4vw 0">
        <van-button
          round type="primary" color='#409eff' block @click="onSubmit"
          style="font-weight: 550"
          >提报</van-button
        >
      </div>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      projectName: "",
      statusDescription: "",
      jobContent:'',
      consequenceAnalysis:'',
      fileList: [],
      head_img:''
    };
  },
  
  computed: {},
  watch: {},
  methods: {},
  created() {
  },
  methods: {
    afterRead(file) {
      // 上传图片
      let formData = new FormData()
      formData.append("file", file.file)
      this.$post({
        url: "/common/minio/upload",
        data: formData,
        upload: true,
        success: (res) => {
          this.head_img = res.url
        },
      })
    },
    onSubmit() {
      if (!this.projectName) return this.$toast("请输入项目名称")
      if (!this.statusDescription) return this.$toast("请输入现状描述")
      if (!this.consequenceAnalysis) return this.$toast("请输入后果分析")
      if (!this.jobContent) return this.$toast("请输入改善方案")
      this.$post({
        url: "/work/work",
        loading: true,
        data: {
          projectName: this.projectName,
          statusDescription: this.statusDescription,
          jobContent:this.jobContent,
          consequenceAnalysis:this.consequenceAnalysis,
          beforePhoto:this.head_img
        },
        success: (res) => {
          this.toast({
            message: "提报成功",
            onClose: () => { 
              this.goReplace("/")
            },
          })
        },
        tip: () => { },
      })
    },
   
   
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #ddd;
  padding: 1vw 3vw;
  /deep/ .van-field__label {
    width: 19vw;
    margin-right: 2vw;
  }
  .divfield {
    border-radius: 2vw;
    margin: 3vw 0;
  }
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>