<template>
  <div class="qrcodebox">
    <div id="qrcode" ref="qrCode"></div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  props: {
    //生成二维码内容
    text: {
      type: String,
      default: "测试内容",
    },
    // 是否生成链接
    url: {
      type: Boolean,
      default: false,
    },
    // 生成链接跳转路由
    to: {
      type: String,
      default: "register",
    },
    //生成二维码宽高
    size: {
      type: [String, Number],
      default: 160,
    },
  },
  watch: {
    text: {
      handler(newdata, olddata) {
        newdata && this.bindQRCode(newdata);
      },
      immediate: true,
    },
  },
  methods: {
    bindQRCode(text) {
      this.$nextTick(() => {
        let data = this.url
          ? `${window.location.origin}/#/${this.to}?code=${text}`
          : this.text;
        new QRCode(this.$refs.qrCode, {
          text: data,
          width: this.size,
          height: this.size,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L, // 容错率
        });
      });
    },
  },
};
</script>
<style lang='less' scoped>
.qrcodebox {
  display: flex;
  justify-content: center;
}
</style>