var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"indexbox"},[_c('nav-bar',{attrs:{"title":"提报列表","left-arrow":"","bg-color":"#409eff","left-color":"#ffffff","title-color":"#fff","right-color":"#fff","right-text":"新增"},on:{"click-right":function($event){return _vm.router('addImprovement')}}}),_c('div',{staticClass:"bag"},[_c('van-tabs',{attrs:{"type":"card"},model:{value:(_vm.listbox),callback:function ($$v) {_vm.listbox=$$v},expression:"listbox"}},[_c('van-tab',{attrs:{"title":"待提交"}},[_c('vant-up-down',{ref:"list",staticClass:"listbox",attrs:{"res":"res.rows","url":"/work/work/list","data":{ state: '已创建', auditStatus: '待提交' }},on:{"list":(val) => (_vm.list = val)}},_vm._l((_vm.list),function(val){return _c('div',{key:val.num,staticClass:"items",on:{"click":function($event){return _vm.router('improvementdetail?id=' + val.id + '&type=提报列表')}}},[_c('div',{staticClass:"itemsleft"},[_c('div',{staticClass:"leftcon"},[_c('p',[_vm._v("项目名称："+_vm._s(val.projectName))]),_c('p',{staticClass:"rightcon",style:({ color: _vm.filtercolor(val.state) })},[_vm._v(" "+_vm._s(val.state)+" ")])]),_c('p',{staticClass:"two"},[_vm._v("提案人："+_vm._s(val.nickName))]),_c('p',{staticClass:"two"},[_vm._v("提案单位："+_vm._s(val.deptName))]),_c('p',{staticClass:"two"},[_vm._v("提案时间："+_vm._s(val.createTime))]),_c('p',[_vm._v(" 提案编号："),_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.copyData(val.code)}}},[_vm._v(_vm._s(val.code))])])]),(val.auditStatus != '通过')?_c('div',{staticClass:"itembottom"},[(
                  _vm.filterpermissions('work:work:remove') &&
                  val.state == '已创建' &&
                  val.auditStatus == '待提交'
                )?_c('van-button',{staticClass:"btyn",attrs:{"type":"primary","color":"red","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.delt(val.id)}}},[_vm._v("删除")]):_vm._e(),(
                  _vm.filterpermissions('work:work:edit') &&
                  val.state == '已创建' &&
                  val.auditStatus == '待提交'
                )?_c('van-button',{staticClass:"btyn",attrs:{"type":"primary","size":"small","color":"#07c160"},on:{"click":function($event){$event.stopPropagation();return _vm.router('editImprovement?id=' + val.id)}}},[_vm._v("修改")]):_vm._e(),(
                  _vm.filterpermissions('work:work:cancel') &&
                  val.auditStatus == '待审核'
                )?_c('van-button',{staticClass:"btyn",attrs:{"type":"primary","color":"#ff9292","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.chexiao(val, val.id)}}},[_vm._v("撤销")]):_vm._e(),(
                  _vm.filterpermissions('work:work:submit') &&
                  val.state == '已创建' &&
                  val.auditStatus == '待提交'
                )?_c('van-button',{staticClass:"btyn",attrs:{"type":"primary","size":"small","color":"#409eff"},on:{"click":function($event){$event.stopPropagation();return _vm.onSubmit(val, val.id)}}},[_vm._v("提交")]):_vm._e()],1):_vm._e()])}),0)],1),_c('van-tab',{attrs:{"title":"全部"}},[_c('vant-up-down',{ref:"list",staticClass:"listbox",attrs:{"res":"res.rows","url":"/work/work/list"},on:{"list":(val) => (_vm.lists = val)}},_vm._l((_vm.lists),function(val){return _c('div',{key:val.num,staticClass:"items",on:{"click":function($event){return _vm.router('improvementdetail?id=' + val.id + '&type=提报列表')}}},[_c('div',{staticClass:"itemsleft"},[_c('div',{staticClass:"leftcon"},[_c('p',[_vm._v("项目名称："+_vm._s(val.projectName))]),_c('p',{staticClass:"rightcon",style:({ color: _vm.filtercolor(val.state) })},[_vm._v(" "+_vm._s(val.state)+" ")])]),_c('p',{staticClass:"two"},[_vm._v("提案人："+_vm._s(val.nickName))]),_c('p',{staticClass:"two"},[_vm._v("提案单位："+_vm._s(val.deptName))]),_c('p',{staticClass:"two"},[_vm._v("提案时间："+_vm._s(val.createTime))]),_c('p',[_vm._v(" 提案编号："),_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.copyData(val.code)}}},[_vm._v(_vm._s(val.code))])])]),(val.auditStatus != '通过')?_c('div',{staticClass:"itembottom"},[(
                  _vm.filterpermissions('work:work:remove') &&
                  val.state == '已创建' &&
                  val.auditStatus == '待提交'
                )?_c('van-button',{staticClass:"btyn",attrs:{"type":"primary","color":"red","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.delt(val.id)}}},[_vm._v("删除")]):_vm._e(),(
                  _vm.filterpermissions('work:work:edit') &&
                  val.state == '已创建' &&
                  val.auditStatus == '待提交'
                )?_c('van-button',{staticClass:"btyn",attrs:{"type":"primary","size":"small","color":"#07c160"},on:{"click":function($event){$event.stopPropagation();return _vm.router('editImprovement?id=' + val.id)}}},[_vm._v("修改")]):_vm._e(),(
                  _vm.filterpermissions('work:work:cancel') &&
                  val.auditStatus == '待审核'
                )?_c('van-button',{staticClass:"btyn",attrs:{"type":"primary","color":"#ff9292","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.chexiao(val, val.id)}}},[_vm._v("撤销")]):_vm._e(),(
                  _vm.filterpermissions('work:work:submit') &&
                  val.state == '已创建' &&
                  val.auditStatus == '待提交'
                )?_c('van-button',{staticClass:"btyn",attrs:{"type":"primary","size":"small","color":"#409eff"},on:{"click":function($event){$event.stopPropagation();return _vm.onSubmit(val, val.id)}}},[_vm._v("提交")]):_vm._e()],1):_vm._e()])}),0)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }