<template>
    <div class="indexbox">
      <nav-bar
        :title="type+'详情'"
        left-arrow
        bg-color="#409eff"
        left-color="#ffffff"
        title-color="#fff"
      />
      <van-field
        class="divfield margin"
        v-model="projectName"
        :rows="1"
      autosize
      type="textarea"
        readonly
        label="项目名称"
        placeholder="项目名称"
      />
      <van-field
      v-model="code"
      readonly
      label="提案编号"
      placeholder="提案编号"
    />
      <van-field name="uploader" readonly label="改善前照片">
        <template #input>
          <van-uploader v-model="fileList" readonly :deletable="false" :max-count="1">
          </van-uploader>
        </template>
      </van-field>
      <van-field
        v-model="statusDescription"
        :rows="1"
        readonly
        autosize
        label="现状描述"
        type="textarea"
        placeholder="现状描述"
      />
      <van-field
        v-model="consequenceAnalysis"
        :rows="1"
        autosize
        readonly
        label="后果分析"
        type="textarea"
        placeholder="后果分析"
      />
      <van-field
        v-model="jobContent"
        :rows="1"
        autosize
        readonly
        label="改善方案"
        type="textarea"
        placeholder="改善方案"
      />
      <van-field
        v-model="deptName"
        readonly
        label="提案单位"
        placeholder="提案单位"
      />
      <van-field
        v-model="createTime"
        readonly
        label="提案时间"
        placeholder="提案时间"
      />
      <van-field
        class="divfieldbt"
        v-model="nickName"
        readonly
        label="提案人"
        :border="false"
        placeholder="提案人"
      />
      <van-field
        class="margin divfield"
        v-model="planDeadline"
        v-if="state=='进行中'||state=='已完工'"
        readonly
        label="计划时间"
        placeholder="完成时间"
      />
      <van-field
        v-model="completionDeadline"
        v-if="state=='已完工'"
        readonly
        label="实际时间"
        placeholder="完成时间"
      />
      <van-field
      v-model="improvementType"
      v-if="state=='进行中'||state=='已完工'"
      readonly
      label="改善类型"
      placeholder="改善类型"
    />
    <van-field
      v-model="principalPersonId"
      v-if="state=='进行中'||state=='已完工'"
      :rows="1"
      readonly
      autosize
      label="实施人"
      type="textarea"
      placeholder="实施人"
    />
    <van-field
      class="divfieldbt"
      v-model="remark"
      v-if="state=='进行中'||state=='已完工'"
      :rows="1"
      readonly
      autosize
      label="备注"
      type="textarea"
      placeholder="备注"
    />
      <van-field name="uploader" readonly label="改善后照片" v-if="evaluateState=='已评价'" class="divfield margin">
        <template #input>
          <van-uploader v-model="fileLists" readonly :deletable="false" :max-count="1">
          </van-uploader>
        </template>
      </van-field>
      <van-field
      class="divfieldbt"
        v-model="improvementEffectEvaluation"
        :rows="1"
        autosize
        label="改善后评价"
        v-if="evaluateState=='已评价'"
        readonly
        type="textarea"
        placeholder="改善后评价"
      />
      <van-field
      class="divfield margin"
        v-model="score"
        readonly
        v-if="evaluateState=='已评价'"
        label="奖励分数"
        placeholder="奖励分数"
      />
      <van-field
        class="divfieldbt"
        v-model="otherRewards"
        :rows="1"
        autosize
        readonly
        v-if="evaluateState=='已评价'"
        label="其它奖励"
        type="textarea"
        placeholder="其它奖励"
      />
    </div>
  </template>
      <script>
  export default {
    data() {
      return {
        projectName: "",
        statusDescription: "",
        jobContent: "",
        consequenceAnalysis: "",
        fileList: [],
        fileLists:[],
        planDeadline: "",
        score:0,
        otherRewards:'',
        showPicker: false,
        state:'',
        code:'',
        completionDeadline:'',
        deptName:'',createTime:'',nickName:'',
        evaluateState:'',
        improvementType: "", principalPersonId: "", remark: "",
        auditStatus:'',
        improvementEffectEvaluation:'',
        id: "",
        type:''
      };
    },
  
    computed: {},
    watch: {},
    methods: {},
    created() {
      this.id = this.$route.query.id;
      this.getdetail();
      if(this.$route.query.type){
        this.type = this.$route.query.type;
      }
    },
    methods: {
      getdetail() {
        this.$get({
          url: "/work/work/" + this.id,
          loading: true,
          success: (res) => {
            this.projectName = res.data.projectName;
            this.code=res.data.code
            this.fileList.push({url: res.data.beforePhoto});
            this.fileLists.push({url: res.data.afterPhoto});
            this.consequenceAnalysis = res.data.consequenceAnalysis;
            this.planDeadline = res.data.planDeadline;
            this.improvementEffectEvaluation = res.data.improvementEffectEvaluation;
            this.state = res.data.state;
            this.deptName = res.data.deptName;
            this.createTime = res.data.createTime;
            this.nickName = res.data.nickName;
            this.completionDeadline=res.data.completionDeadline;
            this.evaluateState = res.data.evaluateState;
            this.auditStatus = res.data.auditStatus;
            this.statusDescription = res.data.statusDescription;
            this.jobContent = res.data.jobContent;
            this.score = res.data.score;
            this.improvementType = res.data.improvementType;
          this.principalPersonId = res.data.principalPersonId;
          this.remark = res.data.remark;
            this.otherRewards=res.data.otherRewards;
          },
          tip: () => {},
        });
      },
    },
    mounted() {},
  };
  </script>
      <style lang='less' scoped>
  .indexbox {
    width: 100%;
    min-height: 100vh;
    background-color: #ddd;
    padding: 1vw 3vw 4vw;
    /deep/ .van-field__label {
      width: 19vw;
      margin-right: 2vw;
    }
    .margin{
      margin-top:3vw;
    }
    .divfield {
      border-radius: 2vw 2vw 0 0;
    }
    .divfieldbt {
      border-radius: 0 0 2vw 2vw;
    }
    .preview-cover {
      position: absolute;
      bottom: 0;
      box-sizing: border-box;
      width: 100%;
      padding: 4px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      background: rgba(0, 0, 0, 0.3);
    }
  }
  </style>