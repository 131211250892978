<template>
  <div class="indexbox">
    <nav-bar
      title="完工"
      left-arrow
      bg-color="#409eff"
      left-color="#ffffff"
      title-color="#fff"
    />
    <van-field
    class="divfield margin"
      v-model="projectName"
      :border="false"
      disabled
      label="项目名称"
      placeholder="项目名称"
    />
    <van-field
      v-model="code"
      disabled
      :border="false"
      label="提案编号"
      placeholder="提案编号"
    />
    <van-field name="uploader" disabled label="改善前照片">
      <template #input>
        <van-uploader v-model="fileList" disabled :deletable="false" :max-count="1">
        </van-uploader>
      </template>
    </van-field>
    <van-field
      v-model="statusDescription"
      :rows="1"
      disabled
      autosize
      label="现状描述"
      type="textarea"
      placeholder="现状描述"
    />
    <van-field
      v-model="consequenceAnalysis"
      :rows="1"
      autosize
      disabled
      label="后果分析"
      type="textarea"
      placeholder="后果分析"
    />
    <van-field
      v-model="jobContent"
      :rows="1"
      autosize
      disabled
      label="改善方案"
      type="textarea"
      placeholder="改善方案"
    />
    <van-field
        v-model="deptName"
        disabled
        label="提案单位"
        placeholder="提案单位"
      />
      <van-field
        v-model="createTime"
        disabled
        label="提案时间"
        placeholder="提案时间"
      />
      <van-field
        class="divfieldbt"
        v-model="nickName"
        disabled
        label="提案人"
        :border="false"
        placeholder="提案人"
      />
    <van-field
      class="divfield margin"
      v-model="planDeadline"
      :border="false"
      disabled
      label="计划时间"
      placeholder="完成时间"
    />
    <van-field
      v-model="improvementType"
      disabled
      label="改善类型"
      placeholder="改善类型"
    />
    <van-field
      v-model="principalPersonId"
      :rows="1"
      disabled
      label="实施人"
      autosize
      type="textarea"
      placeholder="实施人"
    />
    <van-field
      class="divfieldbt"
      v-model="remark"
      :rows="1"
      disabled
      autosize
      label="备注"
      type="textarea"
      placeholder="备注"
    />
    <van-field name="uploader" label="改善后照片" class="divfield margin">
        <template #input>
          <van-uploader v-model="fileLists" :max-count="1" :after-read="afterRead">
          </van-uploader>
        </template>
      </van-field>
    <van-field
      class="divfieldbt"
      v-model="improvementEffectEvaluation"
      :rows="1"
      autosize
      label="改善后评价"
      type="textarea"
      maxlength="200"
      show-word-limit
      placeholder="改善后评价"
    />
    <div style="margin: 4vw 0">
      <van-button
        round
        type="primary"
        color="#409eff"
        block
        @click="onSubmit"
        style="font-weight: 550"
        >完工</van-button
      >
    </div>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      projectName: "",
      statusDescription: "",
      jobContent: "",
      consequenceAnalysis: "",
      fileList: [],
      fileLists:[],
      planDeadline: "",
      code:'',
      deptName:'',createTime:'',nickName:'',
      improvementEffectEvaluation:'',
      head_img:'',
      id: "",
      improvementType: "", principalPersonId: "", remark: "",
      obj:null
    };
  },

  computed: {},
  watch: {},
  methods: {},
  created() {
    this.id = this.$route.query.id;
    this.getdetail();
  },
  methods: {
    afterRead(file) {
      // 上传图片
      let formData = new FormData()
      formData.append("file", file.file)
      this.$post({
        url: "/common/minio/upload",
        data: formData,
        upload: true,
        success: (res) => {
          this.head_img = res.url
        },
      })
    },
    getdetail() {
      this.$get({
        url: "/work/work/" + this.id,
        loading: true,
        success: (res) => {
          this.obj=res.data?res.data:null
          this.projectName = res.data.projectName;
          this.code=res.data.code
          this.fileList.push({url: res.data.beforePhoto});
          this.deptName = res.data.deptName;
            this.createTime = res.data.createTime;
            this.nickName = res.data.nickName;
          this.consequenceAnalysis = res.data.consequenceAnalysis;
          this.statusDescription = res.data.statusDescription;
          this.jobContent = res.data.jobContent;
          this.improvementType = res.data.improvementType;
          this.principalPersonId = res.data.principalPersonId;
          this.remark = res.data.remark;
          this.planDeadline=res.data.planDeadline
        },
        tip: () => {},
      });
    },
    onSubmit() {
      if (!this.improvementEffectEvaluation) return this.$toast("请输入改善后评价");
      this.obj.afterPhoto=this.head_img
      this.obj.improvementEffectEvaluation=this.improvementEffectEvaluation
      this.obj.state = "已完工";
      this.obj.auditStatus = "通过";
      this.$put({
        url: "/work/work",
        loading: true,
        data: this.obj,
        success: (res) => {
          this.toast({
            message: "已完工",
            onClose: () => {
              this.$router.go(-1);
            },
          });
        },
        tip: () => {},
      });
    },
  },
  mounted() {},
};
</script>
    <style lang='less' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  background-color: #ddd;
  padding: 1vw 3vw 4vw;
  /deep/ .van-field__label {
    width: 19vw;
    margin-right: 2vw;
  }
  .margin{
      margin-top:3vw;
    }
    .divfield {
      border-radius: 2vw 2vw 0 0;
    }
    .divfieldbt {
      border-radius: 0 0 2vw 2vw;
    }
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
}
</style>