<template>
  <div class="loginbox">
    <div class="frombox">
      <div class="img"></div>
      <!-- <img class="img" src="@/assets/img/logo.png" alt="" /> -->
      <p class="p">阳春数字精酿智慧访客平台</p>
      <van-field
        clearable
        v-model="name"
        maxlength="11"
        type="tel"
        label="被访人姓名"
        placeholder="请输入被访人姓名"
        :border="false"
      />
      <van-field
        clearable
        v-model="phone"
        type="phone"
        label="被访人手机号"
        placeholder="请输入被访人手机号"
        :border="false"
      />
      <!-- <div class="passbox">
        <router-link to="phone">忘记密码</router-link>
        <router-link to="register">立即注册</router-link>
      </div> -->
      <van-button round type="primary" color="#9f4000" block @click="loginBtn"
        >下一步</van-button
      >
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      name: "yangchun",
      phone: "13705363906",
      logo: "",
      code:''
    };
  },
  computed: {},
  watch: {},
  methods: {
    getopenid() {
      var obj={}
      obj.code=this.code?this.code:''
      this.$post({
        url: "/codetoopenid",
        loading: true,
        data:obj,
        success: (res) => {
          console.log(res.data)
          var openids =res.data.openid;
          localStorage.setItem('openids', openids);
        },
        tip: () => {},
      });
    },
    getUrlparam(variable) {
      var url = window.location.href.split("?");
      var query = window.location.href.split("?")[url.length - 1];
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return null;
    },
    loginBtn() {
      if (!this.name) return this.$toast("请输入被访人姓名");
      if (!this.phone) return this.$toast("请输入被访人手机号");
      var  data= {
          查询条件: "手机号码="+"'"+this.phone+"'"
        }
      this.$post({
        url:
          "/AuthEmployeefiles_list",
        loading: true,
        noToken: true,
        data:data,
        success: (res) => {
          var arr =res.data.list
          if(arr.length==0){
           return false
          }
          this.local.set("phone", arr[0].ID);
          this.toast({
            message: "进入访客预约",
            onClose: () => {
              // this.goReplace("/");
              this.$router.push("register")
            },
          });
        },
        tip: () => {},
      });
    },
  },
  created() {
    this.local.del("openids");
    this.code= this.getUrlparam("code")
    // this.code=this.$route.query.code
    // this.code='051IoIGa1vTFBH0P7kJa1TqUgk2IoIGj'
    this.getopenid()
  },
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.loginbox {
  width: 100%;
  min-height: 100vh;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  // align-items: center;
  background:  #f3ccb2;
  // background: url("~@/assets/img/bj01.jpg");
  // background-size: 100% 100%;
  .frombox {
    width: 100%;
    margin-bottom: 5vw;
    .img {
      width: 50%;
      height: 10vw;
      margin: 18vw auto 2vw;
      display: flex;
    }
    .p {
      text-align: center;
      color: #fff;
      font-size: 7vw;
      margin-bottom: 22vw;
    }
    /deep/.van-cell {
      margin-bottom: 4vw;
      border-radius: 25px;
      // background: rgba(210, 210, 210, 0.4);
      .van-field__label {
        text-align: center;
      }
    }
    /deep/.van-button {
      margin-top: 40px;
    }
    .passbox {
      width: 100%;
      padding: 5px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > a {
        color: @theme;
        font-size: 14px;
      }
    }
  }
}
</style>
